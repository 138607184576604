import React from 'react'
import { updatePageTrackDigitalData } from './digital-data'

export const asyncComponent = (loadComponent, namedExport) => (
  class AsyncComponent extends React.Component {
    state = {
      Component: null,
    }

    componentWillMount() {
      if (this.props.history) {
        this.unlisten = this.props.history.listen((location, _action) => {
          updatePageTrackDigitalData(`${BASE_URL}${location.pathname}`)
        })
      }
    }

    componentDidMount() {
      loadComponent().then((componentModule) => {
        if (this.props.location) {
          updatePageTrackDigitalData(`${BASE_URL}${this.props.location.pathname}`)
        }
        const module = componentModule.default || componentModule[namedExport]
        this.setState({ Component: module })
      })
    }

    componentWillUnmount() {
      if (this.props.history) {
        this.unlisten()
      }
    }

    render() {
      const { Component } = this.state
      return Component && <Component {...this.props} />
    }
  }
)
