import * as React from 'react'

export interface AppContextProps {
  locale: string;
  switchLocale: (string) => void;
  viewer?: any; // TODO generate types from GQL
  viewerFetched: boolean;
  googleMapsLoaded: boolean;
}

const defaultValue: AppContextProps = {
  locale: 'en',
  switchLocale: () => {},
  viewer: null,
  viewerFetched: null,
  googleMapsLoaded: false,
}

export const AppContext = React.createContext(defaultValue)
