import React, { Fragment } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { Query, Mutation } from 'react-apollo'

import { loadMessages, getLocale, setLocale } from 'src/lib/locale'
import { asyncComponent } from 'src/lib/async-component'
import { DefaultDigitalData } from 'src/lib/digital-data'
import { AppContext } from './AppContext'

import './app.css'

import viewerQuery from './viewer.gql'
import changeLocaleQuery from './changeLocale.gql'

const Home = asyncComponent(() => import(/* webpackChunkName: "routes-home" */ 'src/routes/home/index.js'), 'Home')
const Explore = asyncComponent(() => (
  import(/* webpackChunkName: "routes-explore" */ 'src/routes/explore/index.js')
), 'Explore')
const Transfer = asyncComponent(() => (
  import(/* webpackChunkName: "routes-transfer" */ 'src/routes/transfer/index.js')
), 'Transfer')
const Calendar = asyncComponent(() => (
  import(/* webpackChunkName: "routes-calendar" */ 'src/routes/calendar/Calendar.tsx')
), 'Calendar')
const Events = asyncComponent(() => (
  import(/* webpackChunkName: "routes-events" */ 'src/routes/Events/EventPage.tsx')
), 'Events')
const MyTickets = asyncComponent(() => (
  import(/* webpackChunkName: "routes-my-tickets" */ 'src/routes/MyTickets/MyTickets.tsx')
), 'MyTickets')
const Onsale = asyncComponent(() => (
  import(/* webpackChunkName: "routes-my-tickets" */ 'src/routes/onsales/Onsales.js')
), 'Onsales')

export class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewer: null,
      viewerFetched: SSR_DATA.prerendering || !document.cookie.includes('logged_in=true'),
      locale: props.locale,
      messages: props.messages,
    }
  }

  componentDidMount() {
    const localStorageLocale = getLocale()
    if (localStorageLocale && localStorageLocale !== this.state.locale) {
      setLocale(this.state.locale)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.locale !== prevState.locale) {
      setLocale(this.state.locale)
    }
  }

  setViewer = (viewer) => {
    this.setState({ viewer })
  }

  setViewerFetched = (viewerFetched) => {
    this.setState({ viewerFetched })
  }

  switchLocale = localeChange => (
    async (locale) => {
      const messages = await loadMessages(locale)
      this.setState({ locale, messages })
      if (this.state.viewer) {
        localeChange({ variables: { locale: locale.toUpperCase().replace('-', '_') } })
      }
      window.location.replace(`${window.location.origin}${window.location.pathname}?l=${locale}`)
    }
  )

  render() {
    return (
      <Mutation mutation={changeLocaleQuery}>
        {localeChange => (
          <AppContext.Provider
            value={{
              locale: this.state.locale,
              switchLocale: this.switchLocale(localeChange),
              viewer: this.state.viewer,
              viewerFetched: this.state.viewerFetched,
              googleMapsLoaded: this.props.googleMapsLoaded,
            }}
          >
            <DefaultDigitalData />
            <IntlProvider locale={this.state.locale} messages={this.state.messages}>
              <BrowserRouter>
                <Fragment>
                  {!this.state.viewerFetched && (
                    <Query key={viewerQuery} query={viewerQuery}>
                      {({ error, data }) => {
                        if (!this.state.viewer && data && data.viewer && data.viewer.id) this.setViewer(data.viewer)
                        if (!this.state.viewerFetched && (error || (data && data.viewer))) this.setViewerFetched(true)
                        return null
                      }}
                    </Query>
                  )}
                  <Switch>
                    <Route exact path="/(fan/)?" component={Home} />
                    <Route exact path="/(fan/)?explore" component={Explore} />
                    <Route exact path="/(fan/)?transfers/:token" component={Transfer} />
                    <Route exact path="/(fan/)?calendars/:slug" component={Calendar} />
                    <Route exact path="/(fan/)?events/:slug" component={Events} />
                    <Route exact path="/fan/my-tickets" component={MyTickets} />
                    <Route exact path="/(fan/)?onsales/:artist/(events/)?:slug" component={Onsale} />
                  </Switch>
                </Fragment>
              </BrowserRouter>
            </IntlProvider>
          </AppContext.Provider>
        )}
      </Mutation>
    )
  }
}
