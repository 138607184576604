import { addLocaleData } from 'react-intl'
import { getItem, setItem } from './local-storage'

export const DEFAULT_LOCALE = 'en'
export const LOCAL_STORAGE_LOCALE = 'locale'

export const LOCALES = {
  en: 'English',
  sv: 'Svenska',
  fr: 'Français',
  es: 'Español',
  de: 'Deutsch',
  nl: 'Nederlands',
  nb: 'Norsk Bokmål',
  da: 'Dansk',
  fi: 'Suomen kieli',
  'nl-be': 'Nederlands (België)',
}

const supportedLocale = (locale) => {
  if (!locale) return null

  const localeParts = locale.split('-')
  let formattedLocale

  if (localeParts.length === 2) {
    formattedLocale = `${localeParts[0].toLowerCase()}-${localeParts[1].toLowerCase()}`
  } else {
    formattedLocale = localeParts[0].toLowerCase()
  }

  return LOCALES[formattedLocale] && formattedLocale
}

const localeFromUrl = () => {
  const { searchParams } = new URL(window.location)
  const locale = decodeURIComponent(searchParams.get('l'))
  return supportedLocale(locale)
}

export const getLocale = () => supportedLocale(getItem(LOCAL_STORAGE_LOCALE)) || DEFAULT_LOCALE

export const setLocale = (locale) => {
  if (!SSR_DATA.prerendering) setItem(LOCAL_STORAGE_LOCALE, locale)
}

export const detectInitialLocale = () => localeFromUrl() || getLocale() || DEFAULT_LOCALE

export const loadMessages = async (locale) => {
  let localeDataPromise
  let messagesPromise

  switch (locale) {
    case 'da':
      localeDataPromise = import(/* webpackChunkName: "react-intl-da" */ 'react-intl/locale-data/da')
      messagesPromise = import(/* webpackChunkName: "locales-da" */ '../translations/locales/da.json')
      break
    case 'de':
      localeDataPromise = import(/* webpackChunkName: "react-intl-de" */ 'react-intl/locale-data/de')
      messagesPromise = import(/* webpackChunkName: "locales-de" */ '../translations/locales/de.json')
      break
    case 'es':
      localeDataPromise = import(/* webpackChunkName: "react-intl-es" */ 'react-intl/locale-data/es')
      messagesPromise = import(/* webpackChunkName: "locales-es" */ '../translations/locales/es.json')
      break
    case 'fi':
      localeDataPromise = import(/* webpackChunkName: "react-intl-fi" */ 'react-intl/locale-data/fi')
      messagesPromise = import(/* webpackChunkName: "locales-fi" */ '../translations/locales/fi.json')
      break
    case 'fr':
      localeDataPromise = import(/* webpackChunkName: "react-intl-fr" */ 'react-intl/locale-data/fr')
      messagesPromise = import(/* webpackChunkName: "locales-fr" */ '../translations/locales/fr.json')
      break
    case 'nl':
      localeDataPromise = import(/* webpackChunkName: "react-intl-nl" */ 'react-intl/locale-data/nl')
      messagesPromise = import(/* webpackChunkName: "locales-nl" */ '../translations/locales/nl.json')
      break
    case 'nl-be':
      localeDataPromise = import(/* webpackChunkName: "react-intl-nl" */ 'react-intl/locale-data/nl')
      messagesPromise = import(/* webpackChunkName: "locales-nl-be" */ '../translations/locales/nl-be.json')
      break
    case 'nb':
      localeDataPromise = import(/* webpackChunkName: "react-intl-no" */ 'react-intl/locale-data/nb')
      messagesPromise = import(/* webpackChunkName: "locales-no" */ '../translations/locales/nb.json')
      break
    case 'sv':
      localeDataPromise = import(/* webpackChunkName: "react-intl-sv" */ 'react-intl/locale-data/sv')
      messagesPromise = import(/* webpackChunkName: "locales-sv" */ '../translations/locales/sv.json')
      break
    default:
      localeDataPromise = import(/* webpackChunkName: "react-intl-en" */ 'react-intl/locale-data/en')
      messagesPromise = import(/* webpackChunkName: "locales-en" */ '../translations/locales/en.json')
  }

  // https://github.com/webpack/webpack/issues/7792#issuecomment-408093411
  const localeData = (await localeDataPromise).default
  addLocaleData(localeData)

  const messages = await messagesPromise
  return messages
}
