let fallback = {}

export const setItem = (key, val) => {
  try {
    return localStorage.setItem(key, val)
  } catch (error) {
    fallback[key] = val
    return undefined
  }
}

export const getItem = (key) => {
  try {
    return localStorage.getItem(key)
  } catch (error) {
    return fallback[key] || null
  }
}

export const removeItem = (key) => {
  try {
    return localStorage.removeItem(key)
  } catch (error) {
    delete fallback[key]
    return undefined
  }
}

export const clear = () => {
  try {
    return localStorage.clear()
  } catch (error) {
    fallback = {}
    return undefined
  }
}
