import { useEffect } from 'react'

import buildGTM from 'react-google-tag-manager'

export const useGoogleTagManager = () => {
  const gtm = buildGTM({
    id: GOOGLE_TAG_MANAGER_ID,
  })

  useEffect(() => {
    if (!document.querySelector(`script[src='//www.googletagmanager.com/gtm.js?id=${GOOGLE_TAG_MANAGER_ID}']`)) {
      const script = document.createElement('script')
      script.setAttribute('defer', '')
      script.innerHTML = gtm.scriptAsHTML().replace(/<\/?script>/g, '')
      if (document.body) {
        document.body.appendChild(script)
      }
    }
  }, [gtm])
}

const pageData = (props) => {
  const { type, channel, category, attributes } = props
  const country = props.country || 'CA'

  return {
    pageInfo: {
      destinationURL: window.location.href,
      domain: `UNI_${country}`,
      platform: 'fan',
      publisher: 'universe',
      publisherDivision: country,
      environment: ENVIRONMENT,
      Experience: '',
      pageChannel: channel,
      pageType: type,
      pageName: `UNI_${country}: ${type}`,
      referringURL: localStorage.getItem('referringURL'),
    },
    category,
    attributes,
  }
}

export const setDigitalData = (props) => {
  try {
    const digitalData = {
      page: pageData(props),
      cart: {},
      transaction: {},
    }

    window.digitalData = digitalData
  } catch (e) {
    console.log('Error', e) // eslint-disable-line no-console
  }
}

export const DefaultDigitalData = () => {
  if (!SSR_DATA.prerendering) {
    setDigitalData({
      type: 'Fan Page',
      channel: 'fan_page',
      country: 'CA',
    })

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useGoogleTagManager()
  }

  return null
}

export const googleTagManagerEvent = (event) => {
  if (window.dataLayer && (window.dataLayer.push instanceof Function)) {
    window.dataLayer.push(event)
  }
}

export const getEventAttributesDigitalData = (event) => {
  if (!event) {
    return {}
  }
  const eventStartTime = event?.firstTimeSlot?.startAt
  const eventEndTime = event?.firstTimeSlot?.endAt
  return (
    {
      eventDate: eventStartTime ? new Date(eventStartTime).toLocaleString('en-US').split(', ')[0] : null,
      eventTime: eventStartTime ? new Date(eventStartTime).toLocaleString('en-US').split(', ')[1] : null,
      eventID: event?.id,
      eventName: event?.title,
      eventDateISO: eventStartTime ? new Date(eventStartTime).toISOString() : null,
      eventDateEndISO: eventEndTime ? new Date(eventEndTime).toISOString() : null,
      eventDateStartISO: eventStartTime ? new Date(eventStartTime).toISOString() : null,
      state: event.province,
      city: event.city,
      universeHostID: event.user.id,
      universeHostName: event.user.name,
      discovery: {},
      venueName: event.venueName,
      virtualEvent: event.virtual === true ? 1 : 0,
    }
  )
}

export const getCategoryDigitalData = (event) => {
  if (!event) {
    return {}
  }
  return (
    {
      organization: 'UN',
      primaryCategory: event.category.name,
      primaryCategoryID: event.category.id,
    }
  )
}

export const updatePageTrackDigitalData = (currentURL) => {
  if (localStorage.getItem('currentURL') !== currentURL) {
    localStorage.setItem('referringURL', localStorage.getItem('currentURL'))
    localStorage.setItem('currentURL', currentURL)
    setDigitalData({ destinationURL: currentURL, referringURL: localStorage.getItem('referringURL') })
  }
}
