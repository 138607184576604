import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { sha256 } from 'js-sha256'
import 'promise-polyfill/src/polyfill'

import { detectInitialLocale, loadMessages } from 'src/lib/locale'

import { App } from './app'

const mount = () => {
  const locale = detectInitialLocale()
  const { searchParams } = new URL(window.location)

  if (searchParams.get('lat')) {
    SSR_DATA.lat = parseFloat(decodeURIComponent(searchParams.get('lat')))
  }
  if (searchParams.get('lon')) {
    SSR_DATA.lon = parseFloat(decodeURIComponent(searchParams.get('lon')))
  }
  if (searchParams.get('ssr')) {
    SSR_DATA.prerendering = true
  }

  const customFetch = (uri, options) => {
    const { operationName } = JSON.parse(options.body)
    let endpoint

    if (operationName && operationName.lastIndexOf('Discover', 0) === 0) {
      if (ENVIRONMENT === 'development') {
        endpoint = `${DISCOVER_GRAPHQL}`
      } else {
        endpoint = `${DISCOVER_GRAPHQL}?sha256=${sha256(options.body)}`
      }
    } else if (operationName && operationName.lastIndexOf('Cacheable', 0) === 0) {
      endpoint = `${BASE_URL}/graphql?sha256=${sha256(options.body)}`
    } else {
      endpoint = `${BASE_URL}/graphql`
    }

    return fetch(endpoint, {
      ...options,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': window.CSRF_TOKEN,
      },
    }).then((response) => {
      const csrfToken = response.headers.get('X-CSRF-Token')
      if (csrfToken) {
        window.CSRF_TOKEN = csrfToken
      }
      return Promise.resolve(response)
    })
  }

  const Apollo = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            // eslint-disable-next-line no-console
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          })
        }
        if (networkError) {
          // eslint-disable-next-line no-console
          console.log(`[Network error]: ${networkError}`)
        }
      }),
      createHttpLink({ fetch: customFetch }),
    ]),
    cache: new InMemoryCache(),
    ssrMode: SSR_DATA.prerendering,
  })

  class Index extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        messages: null,
        googleMapsLoaded: false,
      }
    }

    async componentDidMount() {
      const messages = await loadMessages(locale)
      this.setState({ messages })

      // Google Maps
      if (!SSR_DATA.prerendering) {
        const googleScript = document.createElement('script')
        googleScript.id = 'google-map-script'
        googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`
        googleScript.addEventListener('load', () => { this.setState({ googleMapsLoaded: true }) })
        window.document.body.appendChild(googleScript)
      }

      // Sentry
      if (!SSR_DATA.prerendering && SENTRY_URL) {
        const sentryScript = document.createElement('script')
        sentryScript.src = 'https://cdn.ravenjs.com/3.26.4/raven.min.js'
        sentryScript.defer = true
        sentryScript.crossorigin = 'anonymous'
        window.document.body.appendChild(sentryScript)
        sentryScript.addEventListener('load', () => { Raven.config(SENTRY_URL).install() })
      }

      // Amplitude
      if (!SSR_DATA.prerendering) {
        (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script");r.type="text/javascript";r.defer=true;r.src="https://cdn.amplitude.com/libs/amplitude-4.4.0-min.gz.js";r.onload=function(){if(e.amplitude.runQueuedFunctions){e.amplitude.runQueuedFunctions()}else{console.log("[Amplitude] Error: could not load SDK")}};var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i);function s(e,t){e.prototype[t]=function(){this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}var o=function(){this._q=[];return this};var a=["add","append","clearAll","prepend","set","setOnce","unset"];for(var u=0;u<a.length;u++){s(o,a[u])}n.Identify=o;var c=function(){this._q=[];return this};var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"];for(var p=0;p<l.length;p++){s(c,l[p])}n.Revenue=c;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"];function v(e){function t(t){e[t]=function(){e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){e=(!e||e.length===0?"$default_instance":e).toLowerCase();if(!n._iq.hasOwnProperty(e)){n._iq[e]={_q:[]};v(n._iq[e])}return n._iq[e]};e.amplitude=n})(window,document); // eslint-disable-line
        amplitude.getInstance().init(AMPLITUDE_API_KEY)
      }
    }

    render() {
      if (!this.state.messages) return null

      return (
        <ApolloProvider client={Apollo}>
          <ApolloHooksProvider client={Apollo}>
            <App locale={locale} messages={this.state.messages} googleMapsLoaded={this.state.googleMapsLoaded} />
          </ApolloHooksProvider>
        </ApolloProvider>
      )
    }
  }

  if (document.querySelector('#navbar')) {
    ReactDOM.hydrate(<Index />, document.getElementById('root'))
  } else {
    ReactDOM.render(<Index />, document.getElementById('root'))
  }
}

const loadPolyfills = async () => {
  await import(/* webpackChunkName: 'polyfills' */ './polyfills/index')
  mount()
}

if (window.Promise && window.fetch) {
  mount()
} else {
  loadPolyfills()
}
